import React from "react"
import '../App.css';

function Footer() {

    return (
        <div id="footer">
            <p id="footerTxt">Website designed and maintained entirely by me!</p>
        </div>
)
    }

export default Footer