import './App.css';
import Greetings from './components/Greetings.js'
import Skills from './components/Skills.js'
import Contact from './components/Contact.js'
import Footer from './components/Footer.js'
import NavbarFinal from './components/NavbarFinal.js'

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

// function thingsIMade() => {

// }

  return (
    <div class='background'>
      <header/>
      <NavbarFinal />
      <Greetings />
      <Skills />
      <Contact />
      <Footer />
      <p>
        
      </p>
    </div>
  );
}

export default App;
